@use "@angular/material" as mat;
// @import "~@angular/material/theming";

// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
// // @import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import '~swiper/swiper-bundle.min.css';
// @import "~snazzy-info-window/dist/snazzy-info-window.css";
// @import "app/theme/styles/spaces";
// @import "app/theme/styles/theme";
// @import "app/theme/styles/rtl";
@import "app/theme/styles/variables";
@import "app/theme/styles/base";
@include mat.core();
// @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

//? This is not working consistently for some reason
// $atease-teal-color: #00a6ce;
// $atease-teal-bright: #00c2ff;

:root {
  --atease-teal-bright: #00c2ff;
  --atease-teal-color: #00a6ce;
  --atease-teal-color-2: #01a7cf;
  --atease-baby-blue-dark: #7dbed0;
  --atease-baby-blue-light: #b3e3f0;

  --atease-logo-red: #ef3f39;
  --atease-text-red: #fb413f;

  --atease-dark-blue: #243845;
  --atease-grey: #758492;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Inter", Arial, sans-serif, sans-serif;
  font-optical-sizing: auto;
}

h1,
h2,
h3,
h4,
h5,
{
  //? I think a 3rd-party lib like Material is overriding the header font-family if !important is not used
  font-family: "Inter", Arial, sans-serif;
}

button,
a {
  // I think a 3rd-party lib like Material is overriding the header font-family if !important is not used
  font-family: "Lato", Arial, sans-serif;
}

* {
  font-family: "Inter", Arial, sans-serif;
}
